.home{

  background-image: url("../images/IMG_9417-min.jpeg");
  background-position: center;
  background-size:cover;
  height:150vh;
  width: 100%;

}
.home2{
  text-align: center;
  background-color: rgba(0,0,0, 0.6);
  height: 150vh;
  color:white;
  padding-top:50px;
}
.link{
  text-decoration: underline dotted;
  color:white;
}
.link:hover {
  font-weight: bold;
}
.profil{
  border: 2px solid #FFFFFF;
  height: 200px;
}
.title{

  font-Family: Monoton;
  font-Size: 3vw;

}

