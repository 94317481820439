.intro {

  background-image: url("../images/IMG_9417-min.jpeg");
  background-position: center;
  background-size: cover;
  position: inherit;
  height:165vh;
  width: 100%;

}

.intro2 {
  text-align: center;
  background-color: rgba(0, 0, 0, 0.6);
  height:165vh;
  color: white;
  padding-top: 50px;
}

.icon:hover{
  font-weight: bold;
}
li:hover {
  font-weight: bold;
}

/* Si la taille de l'écran est de 601 ou plus, on met la taille du texte à 80px */
@media screen and (min-width: 601px) {
  .Card {
    border: 2px solid #FFFFFF;
    background-color: none;
    margin-left: 100px;
    margin-right: 100px;
  }
}

/* Si la taille de l'écran est de 600px ou moins, on met la taille du texte à 30px */

@media screen and (max-width: 600px) {
  .Card {
    border: 2px solid #FFFFFF;
    background-color: none;
    margin-left: 25px;
    margin-right: 25px;
  }
}