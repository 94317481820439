.footer{
  margin-top:7px;
  padding:10px;
  position: fixed;
  bottom:0;
  left:0;
  width:100%;
  height:15%;
  filter: opacity(50%);
  text-align: center;
  color: white;

}

